import type { SegmentEvent } from '@segment/analytics-next';

import { LOCALE_DEFAULT } from '@/types/LocaleMessageKey';
import { getCookie } from '@/utils/cookie';

import getPageInfo from './getPageInfo';
import type { SegmentFacade } from './types';

const getNextPayload = (payload: SegmentFacade): SegmentFacade => {
  const obj: SegmentEvent = {
    ...payload.obj,
    context: {
      ...payload.obj.context,
      page: getPageInfo(),
      locale: getCookie('NEXT_LOCALE') ?? LOCALE_DEFAULT,
    },
    properties: {
      ...payload.obj.properties,
      page: getPageInfo(),
      name: payload.obj.name,
    },
  };

  return {
    ...payload,
    obj: { ...payload.obj, ...obj },
  } as SegmentFacade;
};

export default getNextPayload;
