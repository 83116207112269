'use client';

import { OuraConsentManager as ConsentManager } from '@jouzen/consent-manager';
import { env } from 'next-runtime-env';

import { getCookie, setCookie } from '@/utils/cookie';

export const SEGMENT_ANONYMOUS_ID_COOKIE = 'ajs_anonymous_id';

interface ConsentManagerProps {
  readonly locale: string;
}

const isDebugging = env('NEXT_PUBLIC_DEBUG_ANALYTICS') === 'true';

// Promotes the session only ajs_anonoymous_id generated on the server to the anonymous id used for client side segment
// This allows us to link the events from before a user consented to tracking with their post -consent events
const upgradeSegmentSessionCookie = () => {
  const sessionId = getCookie(SEGMENT_ANONYMOUS_ID_COOKIE);
  if (sessionId) {
    // setting the raw cookie rather than waiting for window.analytics.setAnonymousId to be available
    // after consent so we do not send any client side events with a different anonymous id
    setCookie(SEGMENT_ANONYMOUS_ID_COOKIE, sessionId, 24 * 365);
  }
};

const OuraConsentManager = ({ locale }: ConsentManagerProps) => {
  const SEGMENT_KEY = env('NEXT_PUBLIC_SEGMENT_KEY');
  const enabled = getCookie('disable-segment') !== 'true';

  if (!SEGMENT_KEY) {
    if (isDebugging) {
      console.log(`ANALYTICS: SEGMENT_KEY not found`);
    }
    return null;
  }

  return (
    <ConsentManager
      intlProps={{ locale }}
      segmentKey={SEGMENT_KEY}
      isEnabled={enabled}
      onAcceptAll={() => {
        upgradeSegmentSessionCookie();
      }}
      onSetPreferences={({ marketing }) => {
        if (marketing) {
          upgradeSegmentSessionCookie();
        }
      }}
    />
  );
};

export default OuraConsentManager;
