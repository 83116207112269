import { env } from 'next-runtime-env';

import { logToDataDog } from '@/utils/logToDatadog';

export interface DebugAnalyticsOptions {
  data: { [key: string]: any };
  level: 'log' | 'warn' | 'error';
  logToDatadog: boolean;
}

const DEFAULT_OPTIONS: DebugAnalyticsOptions = {
  data: {},
  level: 'log',
  logToDatadog: false,
};

export function debugAnalytics(
  message: string,
  options: Partial<DebugAnalyticsOptions> = {},
) {
  const {
    data,
    level,
    logToDatadog: shouldLogToDatadog,
  } = { ...DEFAULT_OPTIONS, ...options };

  const isDebugging = env('NEXT_PUBLIC_DEBUG_ANALYTICS') === 'true';

  if (isDebugging && level && console[level]) {
    console[level](`ANALYTICS: ${message}`, data);
  }

  if (!isDebugging && shouldLogToDatadog) {
    logToDataDog('app', message, data);
  }
}
