/**
 * Before sending to Segment Source:
 * 1. Transforms & Enhances Segment payload
 * 2. Adds checks & guardrails to all our Segment calls
 */

import type { MiddlewareFunction } from '@segment/analytics-next';

import { debugAnalytics } from '@/utils/debugAnalytics';

import getNextPayload from './getNextPayload';

const sourceMiddleware: MiddlewareFunction = ({
  payload: originalPayload,
  next: nextFn,
}) => {
  const eventType = originalPayload.obj.type;
  const nextPayload = getNextPayload(originalPayload);
  const debugData = {
    originalPayload,
    nextPayload,
  };

  try {
    switch (eventType) {
      case 'page':
        debugAnalytics(`Sending page event`, { data: debugData });
        nextFn(nextPayload);
        break;

      case 'track':
        debugAnalytics(`Sending track event`, { data: debugData });
        nextFn(nextPayload);
        break;

      case 'identify':
        debugAnalytics(`"identify" not allowed`, {
          data: debugData,
          level: 'error',
        });
        break;

      default:
        debugAnalytics(`Did not send ${eventType} event`, {
          data: debugData,
          level: 'warn',
        });
    }
  } catch (error) {
    debugAnalytics(`Error sending ${eventType} event`, {
      data: {
        ...debugData,
        error,
      },
      level: 'error',
      logToDatadog: true,
    });
  }
};

export default sourceMiddleware;
