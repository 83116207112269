import { isBrowser } from '@/utils/isBrowser';

const DOCUMENT = isBrowser() ? document : ({} as Window['document']);

const getPageInfo = ({ location, referrer, title } = DOCUMENT) => {
  return {
    path: location.pathname,
    referrer: referrer,
    search: location.search,
    title: title,
    url: location.href,
  };
};

export default getPageInfo;
