'use client';

import type { AnalyticsSnippet } from '@segment/analytics-next';

import { isBrowser } from '@/utils/isBrowser';

import setupAnalytics from './setupAnalytics';
import type { BaseDataLayer, PayloadItems } from './types';

interface DataLayer extends BaseDataLayer {
  eventPath?: string;
  eventAttributes?: PayloadItems;
}

declare global {
  interface Window {
    analytics?: AnalyticsSnippet;
    dataLayer?: DataLayer[];
  }
}

if (isBrowser()) {
  setupAnalytics();
}
